import React from 'react'
import Banner from '../components/Banner'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
// import Header from '../components/Header'
import Projects from '../components/Projects'

function Home() {

  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

  return (
    <>
      {/* <Header /> */}
      <Banner />
      <Projects />
      <Contact />
      <Footer />
    </>
  )
}

export default Home