import React from 'react'
import CardProject from './CardProject'


function Projects() {
  return (
    <>
      <div className='flex flex-col md:flex-row gap-2 lg:w-5/6 lg:m-auto lg:mb-16'>
        <CardProject data-aos="fade-in" />
        <CardProject data-aos="fade-in" />
        <CardProject data-aos="fade-in" />
      </div>
    </>
  )
}

export default Projects