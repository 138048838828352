import React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa'

function Footer() {
  return (
    <footer class="text-center">
      <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto space-y-6">
          <div class="flex justify-center space-x-6">
            <a
              class="text-blue-500 hover:text-opacity-75"
              href="https://github.com/jvictorbap"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <FaGithub size={"30px"} />
            </a>

            <a
              class="text-pink-600 hover:text-opacity-75"
              href="https://www.linkedin.com/in/jvictorbap/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Dribbble"
            >
              <FaLinkedin size={"30px"} />
            </a>
          </div>

          <nav
            class="relative flex flex-col sm:flex-row flex-wrap justify-center gap-8 p-8 text-sm font-bold border-4 border-indigo-500 rounded-xl text-white"
          >
            <a
              class="hover:opacity-75"
              href="#inicio"
              rel="noopener noreferrer"
            >
              Inicio
            </a>

            <a
              class="hover:opacity-75"
              href="#0"
              onClick={() => {
                alert('Seção não construida')
              }}
            >
              Projetos
            </a>

            <a
              class="hover:opacity-75"
              href="#contato"
            >
              Contato
            </a>
          </nav>

          <p class="max-w-lg mx-auto text-xs text-gray-500">
            Só sei que nada sei, e o fato de saber isso, me coloca em vantagem sobre aqueles que acham que sabem alguma coisa.
            <a
              class="block mt-1 text-indigo-600 hover:text-white"
              href="https://www.pensador.com/frase/NDU0NTQ1/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sócrates
            </a>
          </p>

          <p class="text-xs font-medium text-indigo-500">Ⓡ Victor Santiago ™</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer