import React from 'react'

function Banner() {
  return (
    <section id='inicio' class="text-white bg-gray-900 h-screen">
      <div class="max-w-screen-xl px-4 py-32 mx-auto lg:h-screen lg:items-center lg:flex">
        <div class="mx-auto text-center">
          <h1 class="text-3xl lg:h-16 font-extrabold text-transparent sm:text-5xl bg-clip-text bg-gradient-to-r from-green-300 via-blue-500 to-purple-600">
            José Victor Santiago Figueiredo Baptista.
          </h1>

          <p class="max-w-xl mx-auto mt-4 sm:leading-relaxed sm:text-xl">
            Apaixonado por programação desde os 15 anos, atualmente com 21, sempre fui um autodidata e hoje sou certificado em desenvolvimento web full-stack, sempre me qualificando para me tornar um melhor desenvolvedor e futuramente devops.
          </p>

          <div class="flex flex-wrap justify-center gap-4 mt-8">
            <a class="block w-full px-12 py-3 text-sm font-medium text-white bg-blue-600 border border-blue-600 rounded sm:w-auto active:text-opacity-75 hover:bg-transparent hover:text-white focus:outline-none focus:ring" href='#0' onClick={() => {
            alert('Seção em construção')
          }}>
              Ver Projetos
            </a>

            <a class="block w-full px-12 py-3 text-sm font-medium text-white border border-blue-600 rounded sm:w-auto hover:bg-blue-600 active:bg-blue-500 focus:outline-none focus:ring" href='#contato'>
              Contato
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner