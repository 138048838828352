import React from 'react'

function Contact() {
  return (
    <div id='contato' className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
      <div className="max-w-lg mx-auto">
        <h1 className="text-2xl font-bold text-center text-indigo-600 sm:text-3xl">Entre em contato comigo</h1>

        <p className="max-w-md mx-auto mt-4 text-center text-gray-500">
          Ficou com alguma duvida? Gostou do meu trabalho? Ficou curioso em como fiz algum projeto?
        </p>

        <form action="" className="p-8 mt-6 mb-0 space-y-4 rounded-lg shadow-sm shadow-indigo-600">
          <p className="text-lg text-center font-medium text-gray-500">Preencha os campos abaixo</p>

          <div>
            <label for="email" className="text-sm text-gray-300 font-medium">Assunto</label>

            <div className="relative mt-1">
              <input
                type="email"
                id="email"
                className="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                placeholder="Preencha o assunto"
              />
            </div>
          </div>

          <div>
            <label for="password" className="text-sm text-gray-300 font-medium">Mensagem</label>

            <div className="relative mt-1">
              <input
                type="password"
                id="password"
                className="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                placeholder="Preencha a mensagem"
              />
            </div>
          </div>

          <div>
            <label for="password" className="text-sm text-gray-300 font-medium">Contato</label>

            <div className="relative mt-1">
              <input
                type="password"
                id="password"
                className="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                placeholder="Email/Telefone/Linkedin"
              />
            </div>
          </div>

          <button type="button" id='contato' className="block w-full px-5 py-3 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-800" onClick={() => {
            alert('Conexao SMTP ainda nao construida')
          }}>
            Enviar Email
          </button>
        </form>
      </div>
    </div>
  )
}

export default Contact